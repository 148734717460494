@import 'normalize',
'vars',
'base';

.river.fourohfour {
  background-image: url(https://res.cloudinary.com/jcrosenthal/image/upload/v1408050625/404_cjvi7s.gif);
}

a.menu {
  font-family: $open_sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
  color: rgb(255, 255, 255);
  text-align: right;
  position: relative;
  left: 30px;
  border: solid 1px #FFF;
  padding: 7px 10px;

  @include breakpoint(phone) {
    left: 0;
  }
}

.my-name-is {
  font-size: 21px;
  font-style: italic;
  padding-bottom: 10px;
  margin-bottom: 0;
}

#root {
  height: 100%;
}

.portfolio-samples {
  padding: 20px;
  overflow: auto;
  clear: both;

  li {
    transition: all 0.5s linear;

    @include breakpoint(phone) {
      width: 50%;
    }

    img {
      margin: 0 auto;
      display: block;
      transition: all 1s ease;

      &:hover {
        filter: blur(5px);
      }

    }

    &.hide {
      transition: all 0.5s linear;
    }
  }
}

.hide {
  display: none !important;
}

figure {
  margin: 0;

  figcaption {
    margin-top: -60px;
  }
}

