body,
html {
  height: 100%;
  overflow: hidden;
}

body {
  background: #F8F8F8;
}

p {
  font-family: $open_sans;
  font-size: 16px;
  line-height: 150%;
  text-align: left;
}