.contents {
    background: #eee;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  
    -webkit-overflow-scrolling: touch;
    -webkit-transform-style: preserve-3d;
  
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  